.mention a {
  --tw-text-opacity: 1 !important;
  color: rgba(220, 155, 109, var(--tw-text-opacity)) !important;
  line-height: 1.5 !important;
}

.mention-textarea {
  /* margin: 1em 0; */
  --tw-bg-opacity: 1;
  background-color: rgba(41, 41, 41, var(--tw-bg-opacity));
  font-size: 14px;
}

.mention-textarea ::placeholder {
  --tw-text-opacity: 1;
  color: rgba(171, 170, 169, var(--tw-text-opacity));
}

.mention-textarea--multiLine .mention-textarea__control {
}

.mention-textarea--multiLine .mention-textarea__highlighter {
  padding: 0.5rem;
  min-height: 56px;
  box-sizing: border-box !important;
  overflow: auto !important;
  transform: translate(-9px, -1px);
  line-height: 1.5rem;
}

.mention-textarea--multiLine .mention-textarea__input:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 58, 58, var(--tw-bg-opacity));
}

.mention-textarea--multiLine .mention-textarea__input {
  border-width: 0;
  display: block;
  padding-left: 0;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.mention-textarea--multiLine .mention-textarea__input:disabled {
  --tw-text-opacity: 1;
  color: rgba(171, 170, 169, var(--tw-text-opacity));
}

.mention-textarea--multiLine .mention-textarea__input {
  caret-color: #e2a579;
}

.mention-textarea--multiLine .mention-textarea__input:disabled {
  cursor: not-allowed;
}

.mention-textarea--multiLine .mention-textarea__input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-color: transparent;
  --tw-ring-color: transparent;
  border: none !important;
}

.error .mention-textarea--multiLine .mention-textarea__input {
  overflow: auto !important;
}

.error .mention-textarea--multiLine .mention-textarea__input:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 58, 58, var(--tw-bg-opacity));
}

.error .mention-textarea--multiLine .mention-textarea__input {
  border-width: 0;
  display: block;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.error .mention-textarea--multiLine .mention-textarea__input:disabled {
  --tw-text-opacity: 1;
  color: rgba(171, 170, 169, var(--tw-text-opacity));
}

.error .mention-textarea--multiLine .mention-textarea__input {
  min-height: 56px;
}

.error .mention-textarea--multiLine .mention-textarea__input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.mention-textarea__suggestions {
  border-radius: 4px;
  border-width: 1px;
  overflow: hidden;
  background-color: var(--canvas-700) !important ;
}

.mention-textarea__suggestions__list {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(41, 41, 41, var(--tw-bg-opacity)) !important;
  border-radius: 4px !important;
  padding: 0.25rem !important;
  --tw-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.15), 0px 4px 6px -2px rgba(0, 0, 0, 0.1) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.mention-textarea__suggestions__item {
  border-radius: 4px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 12px;
}

.mention-textarea__suggestions__item--focused {
  /* @apply bg-canvas-600; */
}

.mention-textarea--multiLine .mention-textarea__highlighter strong {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(62, 87, 115, var(--tw-bg-opacity)) !important;
  border-radius: 2px !important;
  --tw-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  pointer-events: none;
}
