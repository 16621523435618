@media (max-device-width: 600px) {
  .uppload-modal .drop-area > div,
  .uppload-modal .drop-area > em {
    /* hide display text that is not relevant on mobile*/
    display: none;
  }

  .uppload-modal .drop-area {
    border: none !important;
  }
}

.uppload-modal-bg {
  background-color: var(--canvas-700);
}
