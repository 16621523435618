.coworker-table {
  font-size: 12px;
}

.coworker-table input[type="text"], .coworker-table input[type="email"] {
  border-width: 0;
  padding: 0;
}

.coworker-table input[type="text"],
.coworker-table input[type="email"] {
  font-size: 12px;
}

.coworker-table select {
  width: 100%;
  font-size: 12px;
}

.coworker-table input[type="text"]:focus, .coworker-table input[type="email"]:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.coworker-table input::placeholder {
  color: var(--canvas-400);
}

.coworker-table input[type="text"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.5rem;
  width: 100%;
  caret-color: var(--brand-400);
}

.coworker-table input[type="text"]:hover:not(:disabled) {
}

.coworker-table input:disabled {
}

.coworker-table .header-group {
  position: sticky;
  z-index: 10;
  top: 62px;
}

.coworker-table.no-header-offset .header-group {
  top: 0;
}

.coworker-table .header-cell {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 41, 41, var(--tw-bg-opacity));
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  overflow: hidden;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.coworker-table .header-cell.emphasized {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 75, 54, var(--tw-bg-opacity));
}

.coworker-table .row {
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.coworker-table .row, .coworker-table .row input:not([type="checkbox"]), .coworker-table .row .cell, .coworker-table .row select {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 41, 41, var(--tw-bg-opacity));
}

.coworker-table .row.selected-row .cell,
.coworker-table .row.selected-row input[type="text"] {
  /* @apply bg-canvas-100; */
}

.coworker-table .cell {
  border-right-width: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.5rem;
  overflow: hidden;
}

.coworker-table .cell > * {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.coworker-table .cell:last-child {
  border-right-width: 0;
}

.coworker-table .cell:not(:first-child):not(.cell-type-yourTrust):focus-within {
  border-radius: 6px;
  z-index: 4;
  border: 2px solid var(--canvas-500);
  border-left: 0;
  box-shadow: -2px 0px 0px 0px var(--canvas-500);
}

.coworker-table .cell.error {
  border-radius: 6px;
  border: 2px solid var(--brand-300) !important;
  border-left: 0 !important;
  box-shadow: -2px 0px 0px 0px var(--brand-600) !important;
}

.coworker-table .cell.error input[type="text"] {
  height: auto;
}

.coworker-table.is-scrolled-x .cell:focus-within {
  z-index: 2;
}

.coworker-table .cell-type-name,
.coworker-table .cell-type-name input {
  font-weight: 600;
}
