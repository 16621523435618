._all-font {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
}

body {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.t-marquee {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: -0.5px;
}

.t-title-1 {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-weight: 400;
  font-size: 46px;
  line-height: 52px;
  letter-spacing: -0.5px;
}

.t-title-1.plus {
  font-weight: 600;
}

.t-title-1.plus-plus {
  font-weight: 800;
}

.t-title-2 {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-weight: 400;
  font-size: 40px;
  letter-spacing: -0.2px;
  line-height: 48px;
}

.t-title-2.plus {
  font-weight: 600;
}

.t-title-2.plus-plus {
  font-weight: 800;
}

.t-title-3 {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.2px;
}

.t-title-3.plus {
  font-weight: 600;
}

.t-title-3.plus-plus {
  font-weight: 800;
}

.t-title-4 {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.2px;
}

.t-title-4.plus {
  font-weight: 600;
}

.t-title-4.plus-plus {
  font-weight: 800;
}

/** body classes 👇**/

.t-large {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-size: 18px;
  line-height: 26px;
}

.t-large.plus {
  font-weight: 600;
}

.t-large.plus-plus {
  font-weight: 800;
}

.t-regular {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
}

.t-regular.plus {
  font-weight: 600;
}

.t-regular.plus-plus {
  font-weight: 800;
}

.t-small {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-size: 14px;
  line-height: 18px;
}

.t-small.plus {
  font-weight: 600;
}

.t-small.plus-plus {
  font-weight: 800;
}

.t-mini {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-size: 12px;
  line-height: 16px;
}

.t-mini.plus {
  font-weight: 600;
}

.t-mini.plus-plus {
  font-weight: bold;
}

.t-micro {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-size: 10px;
  line-height: 12px;
}

.t-micro.plus {
  font-weight: 600;
}

.t-micro.plus-plus {
  font-weight: bold;
}

._all-label {
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
}

.t-label-1 {
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 32px;
  font-size: 14px;
  letter-spacing: 1px;
  letter-spacing: 0.8px;
}

.t-label-2 {
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.8px;
}

.t-label-3 {
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 10px;
  letter-spacing: 0.6px;
}

.t-label-4 {
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 8px;
  letter-spacing: 0.4px;
}

/** serifs **/

.t-serif-large {
  font-family: poynter-oldstyle-text, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.t-serif-large.plus {
  font-weight: bold;
}

.t-serif-regular {
  font-family: poynter-oldstyle-text, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.t-serif-regular.plus {
  font-weight: bold;
}

.t-serif-small {
  font-family: poynter-oldstyle-text, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.t-serif-small.plus {
  font-weight: bold;
}

.t-serif-mini {
  font-family: poynter-oldstyle-text, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.t-serif-mini.plus {
  font-weight: bold;
}

.t-serif {
  font-family: poynter-oldstyle-text, serif;
}

.full-formatting-serif p {
  font-family: poynter-oldstyle-text, serif !important;
}

.full-formatting-serif li,
.full-formatting-serif ol {
  font-family: poynter-oldstyle-text, serif !important;
}

.full-formatting-serif h1 {
  font-weight: 600;
  font-family: poynter-oldstyle-text, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.full-formatting-serif h1.plus {
  font-weight: bold;
}

.full-formatting-serif h2 {
  font-family: poynter-oldstyle-text, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.full-formatting-serif h2.plus {
  font-weight: bold;
}

.full-formatting-serif h2 {
  font-weight: 600;
}

.full-formatting-serif h3 {
  font-family: poynter-oldstyle-text, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.full-formatting-serif h3.plus {
  font-weight: bold;
}

.full-formatting-serif h3 {
  font-weight: 800;
}

.full-formatting-serif h4 {
  font-family: poynter-oldstyle-text, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.full-formatting-serif h4.plus {
  font-weight: bold;
}

.full-formatting-serif h4 {
  font-weight: 800;
}

.full-formatting-serif p,
.full-formatting-serif h1,
.full-formatting-serif h2,
.full-formatting-serif h3,
.full-formatting-serif h4 {
  margin-bottom: 1em;
}

.t-title-1.full-formatting-sans-serif h1 {
  font-weight: 800;
}

.t-title-2.full-formatting-sans-serif h1 {
  font-weight: 800;
}

.t-title-3.full-formatting-sans-serif h1 {
  font-weight: 800;
}

.t-title-4.full-formatting-sans-serif h1 {
  font-weight: 800;
}

.full-formatting-sans-serif h1 {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-size: 18px;
  line-height: 26px;
}

.full-formatting-sans-serif h1.plus {
  font-weight: 600;
}

.full-formatting-sans-serif h1.plus-plus {
  font-weight: 800;
}

.t-large.full-formatting-sans-serif h1 {
  font-weight: 800;
}

.t-regular.full-formatting-sans-serif h1 {
  font-weight: 800;
}

.t-small.full-formatting-sans-serif h1 {
  font-weight: 800;
}

.t-mini.full-formatting-sans-serif h1 {
  font-weight: bold;
}

.t-micro.full-formatting-sans-serif h1 {
  font-weight: bold;
}

.full-formatting-sans-serif h1 {
  font-weight: 800;
}

.t-title-1.full-formatting-sans-serif h2 {
  font-weight: 600;
}

.t-title-2.full-formatting-sans-serif h2 {
  font-weight: 600;
}

.t-title-3.full-formatting-sans-serif h2 {
  font-weight: 600;
}

.t-title-4.full-formatting-sans-serif h2 {
  font-weight: 600;
}

.full-formatting-sans-serif h2 {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-size: 18px;
  line-height: 26px;
}

.full-formatting-sans-serif h2.plus {
  font-weight: 600;
}

.t-large.full-formatting-sans-serif h2 {
  font-weight: 600;
}

.full-formatting-sans-serif h2.plus-plus {
  font-weight: 800;
}

.t-regular.full-formatting-sans-serif h2 {
  font-weight: 600;
}

.t-small.full-formatting-sans-serif h2 {
  font-weight: 600;
}

.t-mini.full-formatting-sans-serif h2 {
  font-weight: 600;
}

.t-micro.full-formatting-sans-serif h2 {
  font-weight: 600;
}

.t-serif-large.full-formatting-sans-serif h2 {
  font-weight: bold;
}

.t-serif-regular.full-formatting-sans-serif h2 {
  font-weight: bold;
}

.t-serif-small.full-formatting-sans-serif h2 {
  font-weight: bold;
}

.t-serif-mini.full-formatting-sans-serif h2 {
  font-weight: bold;
}

.t-title-1.full-formatting-sans-serif h3 {
  font-weight: 600;
}

.t-title-2.full-formatting-sans-serif h3 {
  font-weight: 600;
}

.t-title-3.full-formatting-sans-serif h3 {
  font-weight: 600;
}

.t-title-4.full-formatting-sans-serif h3 {
  font-weight: 600;
}

.full-formatting-sans-serif h3 {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-size: 18px;
  line-height: 26px;
}

.full-formatting-sans-serif h3.plus {
  font-weight: 600;
}

.t-large.full-formatting-sans-serif h3 {
  font-weight: 600;
}

.full-formatting-sans-serif h3.plus-plus {
  font-weight: 800;
}

.t-regular.full-formatting-sans-serif h3 {
  font-weight: 600;
}

.t-small.full-formatting-sans-serif h3 {
  font-weight: 600;
}

.t-mini.full-formatting-sans-serif h3 {
  font-weight: 600;
}

.t-micro.full-formatting-sans-serif h3 {
  font-weight: 600;
}

.t-serif-large.full-formatting-sans-serif h3 {
  font-weight: bold;
}

.t-serif-regular.full-formatting-sans-serif h3 {
  font-weight: bold;
}

.t-serif-small.full-formatting-sans-serif h3 {
  font-weight: bold;
}

.t-serif-mini.full-formatting-sans-serif h3 {
  font-weight: bold;
}

.t-title-1.full-formatting-sans-serif h4 {
  font-weight: 600;
}

.t-title-2.full-formatting-sans-serif h4 {
  font-weight: 600;
}

.t-title-3.full-formatting-sans-serif h4 {
  font-weight: 600;
}

.t-title-4.full-formatting-sans-serif h4 {
  font-weight: 600;
}

.full-formatting-sans-serif h4 {
  font-style: normal;
  font-family: "Inter", Helvetica, sans-serif, "Open Sans";
  font-size: 18px;
  line-height: 26px;
}

.full-formatting-sans-serif h4.plus {
  font-weight: 600;
}

.t-large.full-formatting-sans-serif h4 {
  font-weight: 600;
}

.full-formatting-sans-serif h4.plus-plus {
  font-weight: 800;
}

.t-regular.full-formatting-sans-serif h4 {
  font-weight: 600;
}

.t-small.full-formatting-sans-serif h4 {
  font-weight: 600;
}

.t-mini.full-formatting-sans-serif h4 {
  font-weight: 600;
}

.t-micro.full-formatting-sans-serif h4 {
  font-weight: 600;
}

.t-serif-large.full-formatting-sans-serif h4 {
  font-weight: bold;
}

.t-serif-regular.full-formatting-sans-serif h4 {
  font-weight: bold;
}

.t-serif-small.full-formatting-sans-serif h4 {
  font-weight: bold;
}

.t-serif-mini.full-formatting-sans-serif h4 {
  font-weight: bold;
}

.full-formatting ul,
.full-formatting-serif ul {
  list-style-type: disc;
}

.full-formatting li, .full-formatting ol, .full-formatting-serif ol, .full-formatting-serif li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}

.full-formatting ol {
  list-style-type: disc;
}

.full-formatting h3 {
  font-weight: 700;
}

.full-formatting a, .full-formatting-serif a {
  --tw-text-opacity: 1 !important;
  color: rgba(214, 139, 85, var(--tw-text-opacity)) !important;
}

.full-formatting p,
.full-formatting h1,
.full-formatting h2,
.full-formatting h3,
.full-formatting h4 {
  margin-bottom: 1em;
}

.full-formatting h2 {
  margin-top: 2em;
}

.text-clamping-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-clamping-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-clamping-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text-clamping-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
