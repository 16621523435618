.segmented-control input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.segmented-control :is(label, button) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.segmented-control :is(label, button) {
  --tw-border-opacity: 1;
  border-color: rgba(95, 95, 95, var(--tw-border-opacity));
  border-width: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.segmented-control.segmented-control-vertical.segmented-control-fill
  :is(label, button) {
  align-items: flex-start;
}

.segmented-control.segmented-control-horizontal {
  display: inline-flex;
}

.segmented-control.segmented-control-vertical {
  display: flex;
  flex-direction: column;
}

.segmented-control.segmented-control-horizontal
  :is(label, button):first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  position: relative;
}

.segmented-control.segmented-control-vertical :is(label, button):first-of-type {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
}

.segmented-control.segmented-control-horizontal
  :is(label, button):last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.segmented-control.segmented-control-vertical :is(label, button):last-of-type {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.segmented-control.segmented-control-horizontal
  :is(label, button):not(:first-of-type) {
  margin-left: -1px;
}

.segmented-control.segmented-control-vertical
  :is(label, button):not(:first-of-type) {
  margin-top: -1px;
}

.segmented-control input[type="radio"]:focus + label {
  --tw-border-opacity: 1;
  border-color: rgba(95, 95, 95, var(--tw-border-opacity));
  border-width: 1px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(171, 170, 169, var(--tw-ring-opacity));
  z-index: 10;
}

.segmented-control input[type="radio"]:checked + label {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 58, 58, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(231, 228, 226, var(--tw-text-opacity));
}
